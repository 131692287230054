import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { DragSource } from 'react-dnd';
import injectSheet from 'react-jss';
import Content from 'composer-blocks/lib/containers/Content';
import { where } from 'composer-blocks/lib/helpers/hasMany';
import ContentDropZone from './ContentDropZone';
import { setActiveContent, setModifiedContent, openModal } from '../actions/app';
import { moveBlock, deleteBlock, duplicateBlock, copyBlockToClipboard } from '../actions/blocks';
import { moveContent, deleteContent, pasteContent, duplicateContent, copyContentToClipboard } from '../actions/contents';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';
import MenuAndHighlight from '../components/MenuAndHighlight';

const styles = {
  editableWrapper: {
    position: 'relative',
    pointerEvents: 'auto',
    // display: 'flex', // fixes button alignment but messes up rows and navbar
  }
};

const shouldHideMenuIfEditing = type => ([
  contentTypes.TEXT,
  contentTypes.BUTTON,
  contentTypes.EXPANDABLE_ROW,
  contentTypes.FOOTER_INLINE,
  contentTypes.SIMPLE_FOOTER
].indexOf(type) > -1);

class EditableContent extends Component {
  wrapWithDropZone = (dropZoneChildren) => {
    const { editable, isRootContent, contentId, contentIndex, content } = this.props;
    const isNavbar = content.type === contentTypes.NAVBAR;
    const isFooter = content.type === contentTypes.FOOTER || content.type === contentTypes.FOOTER_INLINE || content.type === contentTypes.SIMPLE_FOOTER;
    if (isRootContent || !editable || isNavbar || isFooter) return dropZoneChildren;
    const isColumn = content.type === contentTypes.COLUMN;
    return (
      <ContentDropZone
        isColumn={isColumn}
        contentId={contentId}
        index={contentIndex || 0}
        parentId={content.parentId}
      >
        {dropZoneChildren}
      </ContentDropZone>
    );
  }

  render() {
    const { classes, content, editable, isRootContent,
      connectDragSource, connectDragPreview, isEditing, setActiveContent,
      deleteContentOrBlock, copyToClipboard, canPaste, paste,
      duplicate } = this.props;
    const { type } = content;
    const contentTypeName = isRootContent ? ((type === contentTypes.NAVBAR) ? 'navbar' : (type === contentTypes.FOOTER || type === contentTypes.FOOTER_INLINE || type === contentTypes.SIMPLE_FOOTER ? 'footer' : 'block')) : type;

    let highlight = editable && (
      <MenuAndHighlight
        isEditing={isEditing}
        hideMenuIfEditing={shouldHideMenuIfEditing(type)}
        contentTypeName={contentTypeName}
        connectDragSource={connectDragSource}
        deleteContentOrBlock={deleteContentOrBlock}
        copyToClipboard={copyToClipboard}
        duplicate={duplicate}
        canPaste={canPaste}
        paste={paste}
        isRootContent={isRootContent}
      />
    );

    if (editable && type !== contentTypes.COLUMN && connectDragPreview) {
      return connectDragPreview(
        <div>
          {this.wrapWithDropZone(
            <div className={classes.editableWrapper} onClick={setActiveContent}>
              {highlight}
              <Content
                {...this.props}
              />
            </div>
          )}
        </div>
      );
    }

    return (
      <Content
        {...this.props}
        highlight={highlight}
        wrapWithDropZone={this.wrapWithDropZone}
      />
    );
  }
}

const canPaste = (clipboard, properties, contentId) => {
  const { template, templateType } = clipboard;
  if (!templateType || templateType === 'block') {
    return false;
  }
  const contentProperties = where(properties, 'parentId', contentId);
  const allowedContents = where(contentProperties, 'propertyName', 'allowedContents');
  if (allowedContents.length === 1) {
    return !!allowedContents[0].value && (allowedContents[0].value.indexOf(template.type) > -1);
  }
  return false;
}

const getSourceType = ({ isRootContent, content, ...props }) => (
  isRootContent ?
    ((content.type === contentTypes.NAVBAR) ? 'navbar-block' : (content.type === contentTypes.FOOTER || content.type === contentTypes.FOOTER_INLINE || content.type === contentTypes.SIMPLE_FOOTER ? 'footer-block' : 'block')) :
    ((content.type === contentTypes.COLUMN) ? 'column' : 'content')
);

const sourceSpec = {
  beginDrag(props) {
    return {
      contentId: props.contentId,
      blockIndex: props.blockIndex,
      contentIndex: props.contentIndex,
      parentId: props.content.parentId,
      isRootContent: props.isRootContent,
    }
  },
  endDrag(props, monitor) {
    if (monitor.didDrop()) {
      const result = monitor.getDropResult();
      props.moveContentOrBlock(result.index, result.parentId);
    }
  }
};

const collectDrag = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }
};

const mapStateToProps = ({ app, contents, properties }, { contentId }) => ({
  content: (app && app.modifiedItems.contents[contentId]) || contents[contentId],
  isEditing: app && app.activeContentId === contentId,
  canPaste: canPaste(app.clipboard, properties, contentId)
});

const mapDispatchToProps = (dispatch, { contentId, blockId, isRootContent }) => ({
  setActiveContent: (event) => {
    event.stopPropagation();

    dispatch(setActiveContent(contentId));
  },
  onChangeText: (value) => dispatch(setModifiedContent(contentId, value)),
  copyToClipboard: () => (!!isRootContent ? dispatch(copyBlockToClipboard(contentId)) : dispatch(copyContentToClipboard(contentId))),
  duplicate: () => (!!isRootContent ? dispatch(duplicateBlock({ blockId })) : dispatch(duplicateContent({ contentId }))),
  paste: () => dispatch(pasteContent(contentId)),
  deleteContentOrBlock: (event) => {
    event.stopPropagation();

    if (!!isRootContent) {
      dispatch(openModal(
        'Delete Block',
        'Are you sure you want to delete this block?',
        deleteBlock(blockId),
        null
      ));
    } else {
      dispatch(openModal(
        'Delete Content',
        'Are you sure you want to delete this content?',
        deleteContent(contentId),
        null
      ));
    }
  },
  moveContentOrBlock: (newIndex, newParentId) => {
    if (!!isRootContent) {
      dispatch(moveBlock(blockId, newIndex));
    } else {
      dispatch(moveContent(contentId, newIndex, newParentId))
    }
  },
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectSheet(styles)
);

const enhanceForDrag = compose(
  enhance,
  DragSource(getSourceType, sourceSpec, collectDrag)
);

export const ConnectedContent = enhance(EditableContent);
export const DraggableContent = enhanceForDrag(EditableContent);

export default (props) => (props.editable ?
  <DraggableContent {...props} /> :
  <ConnectedContent {...props} />
);
