import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { setSnackbarProps, fetchComposerSettingsIfNeeded } from "../actions/app";
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import NavBar from '../containers/NavBar';
import SideBar from '../containers/SideBar';
import LivePreview from '../containers/LivePreview';
import DynamicModal from '../containers/DynamicModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import { I18nextProvider } from 'react-i18next';
import clsx from 'clsx';
import  i18nSite  from '../helpers/i18nSite.js';


const styles = (theme) => ({
  eventLoader: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  progress: {
    color: 'white'
  },
  Composer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  mainContainer: {
    display: 'flex',
    height: 'calc(100% - 3em)',
  },
  snackbar: {
    color: ({ snackbarProps }) => snackbarProps.error ? theme.palette.error.dark : 'inherit'
  }
});

class Composer extends Component {
  componentWillMount() {
    const { fetchComposerSettingsIfNeeded } = this.props;

    fetchComposerSettingsIfNeeded();

  }

  onUnload = (event) => {
    if (this.props.pendingChanges) {
      event.returnValue = "You have unsaved changes"
    }
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload)
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload)
  }

  closeSnackbar = () => {
    this.props.setSnackbarProps(null);
  };

  render() {
    const { classes, modalProps, match, events, eventFetchingData, snackbarProps } = this.props;
    const snackbarMessage = (
      <span className={classes.snackbar}>
        {snackbarProps.message}
      </span>
    );

    return events ? (
      <div className={clsx('Composer-Editor', classes.Composer)}>
        <NavBar match={match}/>

        <div className={classes.mainContainer}>
          <SideBar />
          <I18nextProvider i18n={i18nSite}>
            <LivePreview editable preview={false}/>
          </I18nextProvider>
        </div>

        <Snackbar open={!!snackbarProps.message} message={snackbarMessage} autoHideDuration={8000} onClose={this.closeSnackbar}/>

        {modalProps && (
          <DynamicModal {...modalProps}/>
        )}
      </div>
    ) : (
      <div className={classes.eventLoader}>
        {(eventFetchingData.isFetching) && (
          <CircularProgress className={classes.progress} size={50}/>
        )}

        {(eventFetchingData.error) && (
          <h1 style={{color: 'white'}}>
            {eventFetchingData.error}
          </h1>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ app, site, pages }) => ({
  modalProps: app.modalProps,
  eventFetchingData: site.pagination.events,
  events: site.entities.events,
  snackbarProps: app.snackbarProps,
  pendingChanges: app.saveChanges.active,
});

const mapDispatchToProps = {
  fetchComposerSettingsIfNeeded,
  setSnackbarProps
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  DragDropContext(HTML5Backend),
  injectSheet(styles)
);

export default enhance(Composer);
